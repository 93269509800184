/* Module */

/* Android 2.3 :checked fix */
/* stylelint-disable at-rule-no-vendor-prefix */
@-webkit-keyframes fake {
	from {
		opacity: 1;
	}

	to {
		opacity: 1;
	}
}
/* stylelint-enable at-rule-no-vendor-prefix */

@keyframes fake {
	from {
		opacity: 1;
	}

	to {
		opacity: 1;
	}
}

.axs-module {
	animation: fake 1s infinite;
	margin: 0;
	padding-bottom: 42px;
	position: relative;
	/* prevent flickering */
	transform: translate3d(0, 0, 0);
}

.axs-module::after {
	clear: both;
	content: " ";
	display: block;
	font-size: 0;
	height: 0;
	visibility: hidden;
}

/* add padding-top to all modules which follow a module with background and do not have a background itself */
.axs-module-background + .axs-module:not(.axs-module-background) {
	padding-top: 42px;
}

.axs-layer-inner .axs-md-header,
.axs-layer-inner .axs-md-footer {
	display: none;
}

@media all and (min-width: 768px) {
	.axs-module {
		padding-bottom: 48px;
	}

	.axs-module-background + .axs-module:not(.axs-module-background) {
		padding-top: 48px;
	}

}

@media all and (min-width: 1024px) {
	.axs-module {
		padding-bottom: 60px;
	}

	.axs-module-background + .axs-module:not(.axs-module-background) {
		padding-top: 60px;
	}
}

@media all and (min-width: 1440px) {
	.axs-module {
		padding-bottom: 78px;
	}

	.axs-module-background + .axs-module:not(.axs-module-background) {
		padding-top: 78px;
	}
}

@media all and (min-width: 1920px) {
	.axs-module {
		padding-bottom: 96px;
	}

	.axs-module-background + .axs-module:not(.axs-module-background) {
		padding-top: 96px;
	}
}
