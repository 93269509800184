/*************************************************************************

	generic, animation, helper and debug styles v2

*************************************************************************/
html, body {
	height: calc(100% - 50px); /* subtract height of fixed header */
}

body {
	background: var(--color-white);
	margin: 0;
	min-height: calc(100% - 50px); /* subtract height of fixed header */
	width: 100%;
}

@media all and (min-width: 1025px) {
	html, body {
		height: calc(100% - 78px); /* subtract height of fixed header */
	}

	body {
		min-height: calc(100% - 78px); /* subtract height of fixed header */
	}
}

/* stylelint-disable selector-no-vendor-prefix */
::-moz-selection,
::selection {
	background: #c03;
	color: var(--color-white);
}
/* stylelint-enable selector-no-vendor-prefix */

/* subtract height of fixed header */
* {
	box-sizing: border-box;
	margin: 0;
	/* stylelint-disable selector-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-margin-after: 0;
	-webkit-margin-before: 0;
	-webkit-margin-end: 0;
	-webkit-margin-start: 0;
	padding: 0;
	-webkit-padding-start: 0;
	-webkit-tap-highlight-color: transparent;
	/* stylelint-enable selector-no-vendor-prefix, property-no-vendor-prefix */
}

/* clearer */

.clear {
	clear: both;
	font-size: 0;
	height: 0;
}

.clearfix::after {
	clear: both;
	content: "";
	display: block;
	font-size: 0;
	height: 0;
	visibility: hidden;
}

.axs-debug [data-module]::before {
	background: #f00;
	content: attr(data-module);
	display: block;
	height: 25px;
	position: absolute;
	right: 0;
	top: 0;
	width: 100px;
	z-index: 9999;
}

.axs-debug [data-module] {
	outline: 1px solid #f00;
}

.axs-debug [data-container] {
	outline: 1px solid #00f;
}

/* image replace */

.axs-image-replace {
	overflow: hidden;
}

.axs-image-replace::before {
	content: "";
	display: block;
	height: 150%;
	width: 0;
}

/* hiding */

.axs-hidden {
	display: none !important;
}

.axs-visually-hidden {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}

.axs-nonvisible {
	visibility: hidden;
}

/* loader */

.axs-layer .axs-loader {
	background-color: var(--color-white);
	min-height: 500px;
	width: 100%;
}

/* debug + reponsive state */

body.axs-state-is-debug::before {
	background: #f00;
	color: var(--color-white);
	content: "Responsive";
	display: block;
	font-weight: bold;
	padding: 10px;
	position: absolute;
	right: 0;
	top: 15px;
	z-index: 9999;
}

body.axs-state-is-debug::after {
	background: #f00;
	color: var(--color-white);
	content: "Debug-Modus ist aktiviert";
	display: block;
	font-weight: bold;
	height: 20px;
	padding: 5px;
	position: fixed;
	right: 0;
	text-align: right;
	top: 0;
	width: 200px;
	z-index: 9999;
}

img {
	border: 0;
}

.axs-debug-item {
	display: none;
}

.axs-state-is-debug .axs-debug-item {
	border: 1px solid #f00;
	clear: both;
	display: block;
	padding: 3px;
}

/* =================================================================== */

.dev-component-headline {
	background-color: #f00;
	color: var(--color-black);
	display: inline-block;
	font: 20px Verdana, sans-serif;
	margin-bottom: 20px;
	padding: 5px;
}

/* taken from _base.scss */
/* 0px - 767px: 320px    #{$mobile} */

@media all and (min-width: 0) and (max-width: 767px) {
	.hide-mobile {
		display: none !important;
	}
}

/* 768px - 1023px: 786px {#{$tablet} */

@media all and (min-width: 768px) and (max-width: 1023px) {
	.hide-tablet {
		display: none !important;
	}
}

/* 1024px - 1279px: 1024px #{$desktop} */

@media all and (min-width: 1024px) and (max-width: 1279px) {
	.hide-smalldesktop {
		display: none !important;
	}
}

/* 1280px - 1920px: 1280px #{$desktopHD} */

@media all and (min-width: 1280px) and (max-width: 1920px) {
	.hide-largedesktop {
		display: none !important;
	}
}

/* =================================================================== */

@keyframes content-opacity {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* used for wrapper animation after the load of the page */

@keyframes show {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* hidden toggle checkbox / radio */
.axs-hidden-toggle {
	display: none;
	visibility: hidden;
}

ol, ul {
	list-style: none;
}

a {
	text-decoration: none;
}

a svg {
	/* prevent event stealing from inline svgs in links */
	pointer-events: none;
}

.axs-j-scroll {
	cursor: pointer;
}
