/*!
 * #*VERSION-START*#18.61.0#*VERSION-END*#
 */
@charset "UTF-8";

/* basic variables from core-ci */
@import "audi-core-ci-reset-and-basic.min.css"; /* hint: source path is defined in postcss.config.js */
@import "audi-variables.css"; /* hint: source path is defined in postcss.config.js */

/* application */
@import "basic.all.css";
@import "basic.layer.css";
@import "basic.module.css";
@import "basic.off-canvas.css";
@import "basic.slider.css";
@import "basic.icon.css";
@import "basic.theme.css";
@import "far-east.css";
@import "scroll-to-top.css";

/* grid */
@import "grid/grid.old.css";
@import "grid/audi-modules.grid.css";
@import "grid/grid.css";

/* base elements and typography */
@import "elements/basic.buttons.css";
@import "elements/basic.elements-buttons.css";
@import "elements/basic.elements-links.css";
@import "elements/basic.fonts.css";
@import "elements/basic.icons.css";
@import "elements/basic.typography.css";

/* header */
@import "../../../../components/header/header.css";
@import "../../../../components/header-navigation/header-navigation.css";
@import "../../../../components/header-navigation/cookieNotification.css";

/* footer */
@import "footer/module.footer.css";
@import "footer/module.footer-extended.css";
