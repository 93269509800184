.axs-md-footer {
	background-color: var(--color-grey-70);
	color: #6d7579;
	margin-bottom: 0;
	padding-bottom: 0;
	padding-top: 0;
}

.axs-md-share-toggle,
.axs-md-share-list {
	display: none;
	overflow: hidden;
}

.axs-md-share-list {
	align-items: center;
	background-color: var(--color-white);
	border: 1px solid rgba(0, 0, 0, .2);
	box-shadow: none;
	flex-flow: row nowrap;
	left: 0;
	padding: 12px 18px;
	position: absolute;
	top: -15px;
}

.axs-md-share-toggle:checked ~ .axs-md-share-list {
	display: flex;
	overflow: visible;
}

.axs-md-share-toggle-label {
	color: var(--color-grey-20);
	display: block;
	margin-bottom: 0;
}

.axs-md-share-toggle-label::before {
	content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyM3B4IiBoZWlnaHQ9IjIzcHgiIHZpZXdCb3g9Ii03IDE4IDIzIDIzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IC03IDE4IDIzIDIzIiB4bWw6c3BhY2U9InByZXNlcnZlIj48dGl0bGU+c3lzdGVtLVNoYXJlLXNtYWxsPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBpZD0iV2VsY29tZSI+PGcgaWQ9Il94MzFfOTIwcHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNzMuMDAwMDAwLCAtMjE1LjAwMDAwMCkiPjxnIGlkPSJEZWZhdWx0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMTYzLjAwMDAwMCkiPjxnIGlkPSJzeXN0ZW0tU2hhcmUtc21hbGwiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM3Mi4wMDAwMDAsIDUyLjAwMDAwMCkiPjxwYXRoIGlkPSJGaWxsLTEiIGZpbGw9IiNGRkZGRkYiIGQ9Ik00LjUsMThDLTEuODQxLDE4LTcsMjMuMTU5LTcsMjkuNVMtMS44NDEsNDEsNC41LDQxUzE2LDM1Ljg0MSwxNiwyOS41UzEwLjg0MSwxOCw0LjUsMThMNC41LDE4eiBNNC41LDQwQy0xLjI5LDQwLTYsMzUuMjktNiwyOS41Uy0xLjI5LDE5LDQuNSwxOVMxNSwyMy43MSwxNSwyOS41UzEwLjI5LDQwLDQuNSw0MEw0LjUsNDB6Ii8+PHBvbHlsaW5lIGlkPSJGaWxsLTIiIGZpbGw9IiNGRkZGRkYiIHBvaW50cz0iNy43NzcsMzEuNDggOC4yOTYsMzIgMi43MSwzMiA4LjAxNiwyNi42OTEgOC4wMTQsMjcuOTc1IDguOTkzLDI3Ljk3MyA4Ljk5NCwyNSA2LDI1IDYsMjYgNy4yOTQsMjYgMiwzMS4yOTYgMiwyNS42OTcgMi42NjMsMjYuMzUyIDMuMzY2LDI1LjY0MSAxLjQ5NywyMy43OTQgLTAuMzU2LDI1LjY1NiAwLjM1MywyNi4zNjEgMSwyNS43MTEgMSwzMyA4LjM1OSwzMyA3LjgyOSwzMy41NzUgOC41NjQsMzQuMjUzIDEwLjE5MSwzMi40ODYgOC40ODYsMzAuNzc1IDcuNzc3LDMxLjQ4ICIvPjwvZz48L2c+PC9nPjwvZz48L3N2Zz4=");
	display: inline-block;
	margin-right: 10px;
	opacity: .6;
	vertical-align: middle;
}

.axs-share-list-label {
	margin-right: 18px;
}

.axs-md-share-list::after {
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid var(--color-white);
	bottom: -10px;
	content: "";
	display: block;
	height: 0;
	left: 72px;
	position: absolute;
	width: 0;
}

.axs-md-share-toggle:checked ~ .axs-md-share-toggle-label,
.axs-md-share-toggle-label:hover {
	border: none;
	color: var(--color-white);
}

.axs-md-share-toggle:checked ~ .axs-md-share-toggle-label::before,
.axs-md-share-toggle-label:hover::before {
	opacity: 1;
}

.axs-md-footer-nav-wrap {
	padding-top: 50px;
	position: relative;
	width: 100%;
}

.axs-md-footer-nav-sharing li {
	float: left;
	margin-bottom: 0;
}

.asx-md-footer-meta-social {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	position: relative;
}

.axs-md-footer-nav li {
	margin-right: 30px;
}

.axs-md-footer-meta-nav li {
	float: left;
	margin: 0 30px 20px 0;
}

.axs-md-footer-nav .axs-el-lk-nav-01 {
	padding-bottom: 0;
}

.axs-md-social-nav {
	margin: 12px 0;
	text-align: right;
}

.axs-md-social-nav li,
.axs-md-share-list li {
	display: inline-block;
	white-space: nowrap;
}

.axs-md-social-nav li {
	margin: 0 6px 12px 0;
}

.axs-md-social-nav-icon {
	display: inline-block;
	fill: var(--color-white);
	height: 4.8rem;
	stroke-width: 0;
	width: 4.8rem;
}

.axs-md-share-list li {
	margin: 0px 12px 0 0;
}

.axs-md-social-nav li:last-child,
.axs-md-share-list li:last-child {
	margin-right: 0;
}

.axs-icon-sharethispage-link {
	display: inline-block;
	height: 23px;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	width: 23px;
}

.axs-share-list-item-icon {
	display: inline-block;
	fill: var(--color-black);
	height: 2.3rem;
	stroke-width: 0;
	width: 2.3rem;
}

.axs-md-share-list .axs-share-list-label {
	margin-bottom: 0;
}

.axs-icon-footer-link,
.axs-custom-icon-footer-link {
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
}

.axs-icon-footer-link {
	height: var(--icon-size-large);
	width: var(--icon-size-large);
}

.axs-custom-icon-footer-link {
	height: 40px;
	width: 40px;
}

.axs-custom-icon-footer-link img {
	max-width: 100%;
}

.axs-md-share-list a.axs-el-lk.axs-el-lk-icn,
.axs-md-social-nav a.axs-el-lk.axs-el-lk-icn {
	display: inline-block;
	height: 40px;
	margin-left: 0;
	overflow: hidden;
	padding-left: 0;
	text-align: left;
	text-indent: -9999em;
	width: 40px;
}

.axs-md-share-list a.axs-el-lk.axs-el-lk-icn {
	height: 30px;
	width: 30px;
}

.axs-md-share-list a.axs-el-lk.axs-el-lk-icn.axs-el-lk-icn-custom,
.axs-md-social-nav a.axs-el-lk.axs-el-lk-icn.axs-el-lk-icn-custom {
	text-indent: 0;
}

.axs-md-share-list a.axs-el-lk.axs-el-lk-icn.axs-el-lk-icn-custom img,
.axs-md-social-nav a.axs-el-lk.axs-el-lk-icn.axs-el-lk-icn-custom img {
	position: relative;
	width: 100%;
}

.axs-md-share-list  a.axs-el-lk.axs-el-lk-icn::before,
.axs-md-social-nav a.axs-el-lk.axs-el-lk-icn::before {
	height: 40px;
	width: 40px;
}

.axs-md-share-list  a.axs-el-lk.axs-el-lk-icn::before {
	background-color: #e7e9ea;
}

.axs-md-share-list  a.axs-el-lk.axs-el-lk-icn:hover::before {
	background-color: #d3d5d7;
}

a.axs-el-lk.axs-el-lk-icn::before {
	background-color: transparent;
	background-position: center center;
	height: 30px;
	width: 30px;
}

a.axs-el-lk.axs-el-lk-icn:hover::before {
	background-color: transparent;
}

.axs-md-footer-copyright {
	border-top: 1px solid var(--color-grey-60);
	margin: 0;
	padding: 30px 0 60px;
}

.axs-md-footer-copyright .axs-el-pg {
	margin-bottom: 20px;
}

.axs-md-footer-copyright .axs-el-pg:last-child {
	margin-bottom: 0;
}

.axs-md-footer-meta-nav {
	clear: both;
	margin-top: 30px;
}

@media all and (min-width: 1150px) {
	.axs-md-footer-nav {
		width: 100%;
	}

	.axs-md-footer-meta,
	.axs-md-social-nav {
		vertical-align: bottom;
	}
}

@media all and (max-width: 1149px) {
	.axs-md-social-nav {
		clear: both;
		text-align: left;
	}

	.axs-md-footer-nav {
		width: 100%;
	}
}
