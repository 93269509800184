/*
	 BASIC ELEMENTS
	 >>> Buttons <<<
*/

/* - Basic styling - */
.axs-button-standard,
.axs-button-ghost {
	align-items: center;
	border: 1px solid transparent; /* button jumping for hover states with border */
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	/* stylelint-disable property-no-vendor-prefix */

	-ms-flex-align: center;

	/* stylelint-enable property-no-vendor-prefix */
	font-family: AudiTypeNormal, sans-serif;
	font-size: 1.4rem;
	height: 50px;
	line-height: 2rem;
	max-width: 290px;
	min-width: 60px;
	overflow: hidden;
	padding: 0 30px;
	position: relative;
}

/* Allows 2 text-rows with ellipsis */
.axs-button-standard span,
.axs-button-ghost span {
	/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
	-webkit-box-orient: vertical;
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
}

/* Inactive State with data attribute */
.axs-button-standard.inactive,
.axs-button-ghost.inactive {
	pointer-events: none;
}

/* - Button Standard - */
.axs-button-standard,
.axs-button-standard:active,
.axs-button-standard[data-state="active"] {
	background-color: var(--color-black);
	color: var(--color-white);
}

.axs-button-standard:hover,
.axs-button-standard:focus {
	background-color: var(--color-grey-80);
}

.axs-button-standard.inactive {
	background-color: var(--color-grey-30);
}

/* - Button Standard Inverted - */
[data-theme="dark"] .axs-button-standard,
[data-theme="dark"] .axs-button-standard:active,
[data-theme="dark"] .axs-button-standard[data-state="active"] {
	background-color: var(--color-white);
	color: var(--color-grey-90);
}

[data-theme="dark"] .axs-button-standard:hover,
[data-theme="dark"] .axs-button-standard:focus {
	background-color: var(--color-grey-10);
	color: var(--color-grey-60);
}

[data-theme="dark"] .axs-button-standard.inactive {
	background-color: var(--color-grey-70);
	color: var(--color-grey-90);
	cursor: default;
}

/* - Button Ghost - */
.axs-button-ghost,
.axs-button-ghost:active,
.axs-button-ghost[data-state="active"] {
	border-color: var(--color-black);
	color: var(--color-black);
}

.axs-button-ghost:hover,
.axs-button-ghost:focus {
	border-color: var(--color-grey-80);
	color: var(--color-grey-80);
}

.axs-button-ghost.inactive {
	border-color: var(--color-grey-30);
	color: var(--color-grey-30);
}

/* - Button Ghost Inverted - */
[data-theme="dark"] .axs-button-ghost,
[data-theme="dark"] .axs-button-ghost:active,
[data-theme="dark"] .axs-button-ghost[data-state="active"] {
	border-color: var(--color-white);
	color: var(--color-white);
}

[data-theme="dark"] .axs-button-ghost:hover,
[data-theme="dark"] .axs-button-ghost:focus {
	border-color: var(--color-grey-50);
	color: var(--color-grey-50);
}

[data-theme="dark"] .axs-button-ghost.inactive {
	border-color: var(--color-grey-70);
	color: var(--color-grey-70);
	cursor: default;
}