.axs-md-footer-extended {
	background-color: #e7e9ea;
	padding: 60px 0 10px;
}

.axs-md-footer-extended-content {
	margin-bottom: 30px;
	width: 70%;
}

.axs-md-footer-extended-content img {
	margin-bottom: 15px;
	margin-right: 20px;
}

@media all and (min-width: 1025px) {
	.axs-md-footer-extended {
		padding: 60px 0 30px;
	}

	.axs-md-footer-extended-content {
		float: left;
		padding-right: 30px;
		width: 50%;
	}

	.axs-md-footer-extended-content:last-child {
		padding-right: 0;
	}

	div.axs-md-footer-extended h3 {
		width: 48%;
	}
}

@media all and (max-width: 1024px) {
	.axs-md-footer-extended-content {
		width: 100%;
	}
}
