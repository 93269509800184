/*
	 BASIC ELEMENTS
	 >>> Links <<<
*/

/* - Basic styling - */
.axs-link-basic,
.axs-link-back,
.axs-link-cta,
.axs-link-text,
.axs-link-down,
.axs-link-up,
.axs-link-navigation-3 {
	cursor: pointer;
	display: inline-block;
}

/* inactive states */
.axs-link-basic[data-state="inactive"],
.axs-link-back[data-state="inactive"],
.axs-link-cta[data-state="inactive"],
.axs-link-text[data-state="inactive"],
.axs-link-down[data-state="inactive"],
.axs-link-up[data-state="inactive"],
.axs-link-navigation-3[data-state="inactive"] {
	pointer-events: none;
}

/* active states - no events */
.axs-link-basic[data-state="active"],
.axs-link-back[data-state="active"],
.axs-link-cta[data-state="active"],
.axs-link-text[data-state="active"],
.axs-link-down[data-state="active"],
.axs-link-up[data-state="active"],
.axs-link-navigation-3[data-state="active"] {
	pointer-events: none;
}

/* *** basic link ****************************** */
.axs-link-basic,
.axs-link-back,
.axs-link-text,
.axs-link-down,
.axs-link-up {
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-size: 1.4rem;
	line-height: 2rem;
}

/* CTA link */
.axs-link-cta {
	font-family: AudiTypeExtendedNormal, Verdana, sans-serif;
	font-size: 1.4rem;
	line-height: 2rem;
}

/* Navigation Link 2 */
.axs-link-navigation-2 {
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-size: 1.4rem;
	line-height: 1.5rem;
}

/* Navigation Link 3 (Footer) */
.axs-link-navigation-3 {
	font-family: AudiTypeExtendedNormal, Verdana, sans-serif;
	font-size: 1.4rem;
	line-height: 1.5rem;
}

/* basic link */
.axs-link-basic,
.axs-link-back,
.axs-link-cta,
.axs-link-text,
.axs-link-down,
.axs-link-up,
.axs-link-navigation-2,
.axs-link-navigation-3 {
	color: var(--color-grey-70);
}

.axs-link-basic:hover,
.axs-link-basic:active,
.axs-link-basic.active,
.axs-link-back:hover,
.axs-link-back:active,
.axs-link-back.active,
.axs-link-cta:hover,
.axs-link-cta:active,
.axs-link-cta.active,
.axs-link-text:hover,
.axs-link-text:active,
.axs-link-text.active,
.axs-link-down:hover,
.axs-link-down:active,
.axs-link-down.active,
.axs-link-up:hover,
.axs-link-up:active,
.axs-link-up.active,
.axs-link-navigation-2:active,
.axs-link-navigation-2:hover,
.axs-link-navigation-3:hover {
	color: var(--color-black);
}

.axs-link-basic[data-state="inactive"],
.axs-link-back[data-state="inactive"],
.axs-link-cta[data-state="inactive"],
.axs-link-text[data-state="inactive"],
.axs-link-down[data-state="inactive"],
.axs-link-up[data-state="inactive"],
.axs-link-navigation-3[data-state="inactive"],
[data-theme="dark"] .axs-link-basic[data-state="inactive"],
[data-theme="dark"] .axs-link-back[data-state="inactive"],
[data-theme="dark"] .axs-link-cta[data-state="inactive"],
[data-theme="dark"] .axs-link-text[data-state="inactive"],
[data-theme="dark"] .axs-link-down[data-state="inactive"],
[data-theme="dark"] .axs-link-up[data-state="inactive"],
[data-theme="dark"] .axs-link-navigation-3[data-state="inactive"] {
	color: var(--color-grey-50);
}

.axs-link-navigation-2[data-state="inactive"] {
	color: var(--color-grey-30);
}

[data-theme="dark"] .axs-link-navigation-2[data-state="inactive"] {
	color: var(--color-grey-60);
}

/* basic link inverted */
[data-theme="dark"] .axs-link-basic,
[data-theme="dark"] .axs-link-back,
[data-theme="dark"] .axs-link-cta,
[data-theme="dark"] .axs-link-text,
[data-theme="dark"] .axs-link-down,
[data-theme="dark"] .axs-link-up,
[data-theme="dark"] .axs-link-navigation-2:hover,
[data-theme="dark"] .axs-link-navigation-2[data-state="active"],
[data-theme="dark"] .axs-link-navigation-3:hover,
[data-theme="dark"] .axs-link-navigation-3[data-state="active"] {
	color: var(--color-white);
}

[data-theme="dark"] .axs-link-navigation-2 {
	color: var(--color-grey-30);
}

[data-theme="dark"] .axs-link-basic:hover,
[data-theme="dark"] .axs-link-basic:active,
[data-theme="dark"] .axs-link-basic.active,
[data-theme="dark"] .axs-link-back:hover,
[data-theme="dark"] .axs-link-back:active,
[data-theme="dark"] .axs-link-back.active,
[data-theme="dark"] .axs-link-cta:hover,
[data-theme="dark"] .axs-link-cta:active,
[data-theme="dark"] .axs-link-cta.active,
[data-theme="dark"] .axs-link-text:hover,
[data-theme="dark"] .axs-link-text:active,
[data-theme="dark"] .axs-link-text.active,
[data-theme="dark"] .axs-link-down:hover,
[data-theme="dark"] .axs-link-down:active,
[data-theme="dark"] .axs-link-down.active,
[data-theme="dark"] .axs-link-up:hover,
[data-theme="dark"] .axs-link-up:active,
[data-theme="dark"] .axs-link-up.active,
[data-theme="dark"] .axs-link-navigation-3 {
	color: var(--color-grey-20);
}

/* icon general */
.axs-icon-arrow {
	display: inline-block;
	fill: currentColor;
	height: 2rem;
	line-height: 1.4rem;
	vertical-align: text-top;
	width: 2.4rem;
}

/* icon basic link & cta link */
.axs-link-basic .axs-icon-arrow,
.axs-link-cta .axs-icon-arrow {
	margin-left: -3px;
	margin-right: 3px;
	margin-top: -1px;
}

/* icon back link */
.axs-link-back .axs-icon-arrow {
	margin-left: 7px;
	margin-right: -3px;
	transform: rotate(180deg);
	vertical-align: text-top;
	width: 2rem;
}

/* icon down link */
.axs-link-down .axs-icon-arrow {
	margin-bottom: -1px;
	margin-left: 1px;
	transform: rotate(90deg);
}

/* icon up link */
.axs-link-up .axs-icon-arrow {
	margin-bottom: -3px;
	margin-left: -1px;
	margin-right: 3px;
	transform: rotate(270deg);
}

/* text link */
.axs-link-text {
	text-decoration: underline;
}

/* footnote link */
sup[data-footnote] {
	color: var(--color-grey-70);
	cursor: pointer;
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-size: 12px;
	font-weight: normal !important;
	line-height: 1rem;
	margin-bottom: 0;
	margin-left: 1px;
	margin-right: 1px;
}

[data-theme="dark"] sup[data-footnote] {
	color: var(--color-white);
}

sup[data-footnote]:hover,
sup[data-footnote]:active,
sup[data-footnote].active {
	color: var(--color-black) !important;
}

[data-theme="dark"] sup[data-footnote]:hover,
[data-theme="dark"] sup[data-footnote]:active,
[data-theme="dark"] sup[data-footnote].active {
	color: var(--color-grey-20) !important;
}

sup[data-footnote][data-state="inactive"] {
	color: var(--color-grey-50) !important;
	pointer-events: none;
}

sup[data-footnote][data-state="active"] {
	pointer-events: none;
}

sup[data-footnote]:first-child {
	margin-left: 3px;
}

sup[data-footnote]:last-child {
	margin-right: 3px;
}
