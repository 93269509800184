.axs-b-icon {
	overflow: hidden;
}

.axs-b-icon::before {
	background-position: center center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	position: relative;
}

.axs-b1 {
	background-color: var(--color-black);
	color: var(--color-white);
	display: inline-block;
	/* ff */
	padding: 10px 12px;
	text-decoration: none;
}

/* layer button */

.axs-button-close {
	color: var(--color-white);
	cursor: pointer;
	display: block;
	height: 40px;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 40px;
	z-index: 3;
}

.axs-button-close span {
	visibility: hidden;
}

.axs-icon-close::before {
	height: 40px;
	width: 40px;
}