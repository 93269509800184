/********************************************************************
    off-canvas
    mobile-first
*******************************************************************/

.off-canvas-panel:not(:first-child) {
	display: none;
}

/* .is-active-off-canvas-componente --> set by script */
.off-canvas.is-active-off-canvas-componente {
	overflow: hidden;
}

.off-canvas-wrapper.is-active-off-canvas-componente {
	position: relative;
	right: 0;
	transition: all .25s ease-out;
}

.off-canvas-panel.is-active-off-canvas-componente {
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
}
