@import "./grid.vars.css";

.grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: auto;
	max-width: 1920px;
	overflow: hidden;
	width: 100%;
}

.grid--equal-columns,
.grid--equal-columns.grid--equal-columns > .grid__col.grid__col {
	padding-left: var(--grid-inner-padding-half);
	padding-right: var(--grid-inner-padding-half);
}

.grid__col {
	flex: 0 0 auto;
}

/*************************************************************************

	Small / 4 Columns / 0 - 767px

*************************************************************************/

/* column width classes */

@for $i from 1 to 4 {
	.grid__col--s-$(i) {
		width: var(--column-$i);
	}
}

/* column margin classes (empty columns) */
@for $i from 1 to 4 {
	.grid__col--pre-s-$(i) {
		margin-left: var(--column-$i);
	}
}
@for $i from 1 to 4 {
	.grid__col--post-s-$(i) {
		margin-right: var(--column-$i);
	}
}

@media all and (max-width: 767px) {

	/* column paddings for first columns in row */

	@for $i from 1 to 4 {
		.grid--s-items-in-row-$i > .grid__col:nth-child($(i)n + 1) {
			padding-left: var(--grid-inner-padding);
		}
	}

	@for $i from 1 to 4 {
		.grid--s-items-in-row-$i > .grid__col:nth-child($(i)n) {
			padding-right: var(--grid-inner-padding);
		}
	}

	/* fullwidth column */

	.grid__col--full-width {
		width: var(--column-4);
	}

	/* centered grid layout */

	.grid__col--centered,
	.grid__col--centered-large {
		padding-left: var(--grid-inner-padding);
		padding-right: var(--grid-inner-padding);
		width: var(--column-4);
	}
}

/*************************************************************************

	Medium / 6 Columns / 768 - 1023px

*************************************************************************/

@media all and (min-width: 768px) {

	@for $i from 1 to 6 {
		/* column width classes */

		.grid__col--m-$(i) {
			width: var(--column-$i);
		}

		/* column margin classes (empty columns) */
	}

	@for $i from 1 to 6 {
		.grid__col--pre-m-$(i) {
			margin-left: var(--column-$i);
		}
	}

	@for $i from 1 to 6 {
		.grid__col--m-post-$(i) {
			margin-right: var(--column-$i);
		}
	}

	/* fullwidth column */

	.grid__col--full-width {
		width: var(--column-6);
	}

	/* sidebar grid layout */

	.grid__col--sidebar {
		padding-left: var(--grid-inner-padding);
		width: var(--column-3);
	}

	.grid__col--interactive {
		padding-right: var(--grid-inner-padding);
		width: var(--column-3);
	}
}

@media all and (min-width: 768px) and (max-width: 1023px) {
	/* column paddings for first columns in row */

	@for $i from 1 to 6 {
		.grid--m-items-in-row-$(i) > .grid__col:nth-child($(i)n + 1) {
			padding-left: var(--grid-inner-padding);
		}
	}

	@for $i from 1 to 6 {
		.grid--m-items-in-row-$(i) > .grid__col:nth-child($(i)n) {
			padding-right: var(--grid-inner-padding);
		}
	}

	/* centered grid layout */

	.grid__col--centered,
	.grid__col--centered-large {
		padding-left: var(--grid-inner-padding);
		padding-right: var(--grid-inner-padding);
		width: var(--column-6);
	}
}

/*************************************************************************

	Large / 12 Columns /  1024 -1439px

*************************************************************************/

@media all and (min-width: 1024px) {
	/* column width classes */

	@for $i from 1 to 12 {
		.grid__col--l-$(i) {
			width: var(--column-$i);
		}
	}

	/* column margin classes (empty columns) */

	@for $i from 1 to 12 {
		.grid__col--pre-l-$(i) {
			margin-left: var(--column-$i);
		}
	}

	@for $i from 1 to 12 {
		.grid__col--post-l-$(i) {
			margin-right: var(--column-$i);
		}
	}

	/* fullwidth column */

	.grid__col--full-width {
		width: var(--column-12);
	}

	/* sidebar grid layout */

	.grid__col--sidebar {
		width: var(--column-4);
	}

	.grid__col--interactive {
		width: var(--column-8);
	}

	/* centered grid layout */

	.grid__col--centered {
		margin-left: var(--column-3);
		margin-right: var(--column-3);
		width: var(--column-6);
	}

	.grid__col--centered-large {
		margin-left: var(--column-2);
		margin-right: var(--column-2);
		width: var(--column-8);
	}
}

@media all and (min-width: 1024px) and (max-width: 1439px) {
	/* column paddings for first columns in row */

	@for $i from 1 to 12 {
		.grid--l-items-in-row-$i > .grid__col:nth-child($(i)n + 1) {
			padding-left: var(--grid-inner-padding);
		}
	}

	@for $i from 1 to 12 {
		.grid--l-items-in-row-$i > .grid__col:nth-child($(i)n) {
			padding-right: var(--grid-inner-padding);
		}
	}
}

/*************************************************************************

	Extra Large / 12 Columns /  > 1440px

*************************************************************************/

@media all and (min-width: 1440px) {
	/* column width classes */

	@for $i from 1 to 12 {
		.grid__col--xl-$(i) {
			width: var(--column-$i);
		}
	}

	/* column margin classes (empty columns) */

	@for $i from 1 to 12 {
		.grid__col--pre-xl-$(i) {
			margin-left: var(--column-$i);
		}
	}

	@for $i from 1 to 12 {
		.grid__col--post-xl-$(i) {
			margin-right: var(--column-$i);
		}
	}

	/* column paddings for first columns in row */

	@for $i from 1 to 12 {
		.grid--xl-items-in-row-$i > .grid__col:nth-child($(i)n + 1) {
			padding-left: var(--grid-inner-padding);
		}
	}

	@for $i from 1 to 12 {
		.grid--xl-items-in-row-$i > .grid__col:nth-child($(i)n) {
			padding-right: var(--grid-inner-padding);
		}
	}
}
