/* icons */

.axs-icon {
	display: inline-block;
	fill: currentColor;
}

.axs-icon--large {
	height: var(--icon-size-large);
	width: var(--icon-size-large);
}

.axs-icon--small {
	height: var(--icon-size-small);
	width: var(--icon-size-small);
}
