/* Slider dots */
.axs-at-slider {
	bottom: 0;
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
}

.axs-at-slider-input {
	display: none;
}

.axs-at-slider-input-label {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNzc1NkMxN0VCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNzc1NkMxOEVCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA3NzU2QzE1RUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA3NzU2QzE2RUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+rVFjTQAAALRJREFUeNpi/P//PwMpgBFNgxIQm0JpELgHxKehNBgwISl2/vjxY1B3d/dXRUXFfSAMYoPEQHJwVSAbgFjp7du3JSoqKhZAIWNkDBIDyYHUgNTCbDCdO3fujTt37vxGdzNIDCQHdSrcD5UgJzx48OAPNo/KyMiwPH78GOSsNiYGEgFMw72srCxRXIry8vJAcnfJ8jRMAwg7gyS6urp8FBQUzEEYxIYqdoapIzniGElNGgABBgD9N4UAcWCrPwAAAABJRU5ErkJggg==");
	cursor: pointer;
	display: inline-block;
	height: 12px;
	margin: 0 4px;
	width: 12px;
	z-index: 1;
}

.axs-at-slider label.selected {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNzc1NkMxM0VCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNzc1NkMxNEVCRkExMUU0OTIxOUYyNzY5QkNCRkI3QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjA3NzU2QzExRUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjA3NzU2QzEyRUJGQTExRTQ5MjE5RjI3NjlCQ0JGQjdDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+QmWFSAAAALFJREFUeNqUkrEKwyAURaPSqVOGrhk799PaD+raUfEP3Lv7Ew6CYibFvismdCk2Dy4+4jkKvrBa67SVEGKm5UI590+J4kopfocgcM5PlEUp9fDevwlICHop5R17YNrhXVistc/6o7AHpgnUzDilDgq3g4VwxdUjAQxYCLeccxoJYMDy6c9ijLUFwhpjtCMhhAAmQXDGmNdI6Iw7/qzfg9Nab4NbEfT9OffBsaO/xkeAAQC/6hUWzWQARgAAAABJRU5ErkJggg==");
}

.axs-at-slider-arrow-left,
.axs-at-slider-arrow-right {
	background: none;
	color: var(--color-black);
	cursor: pointer;
	display: none;
	height: 4.8rem;
	left: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 4.8rem;
	z-index: 8;
}

.axs-at-slider-arrow-left.inactive,
.axs-at-slider-arrow-right.inactive {
	display: none;
}

.axs-at-slider-arrow-right {
	left: auto;
	right: 0;
}

.axs-slider-content {
	height: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.axs-slider-content > ul {
	height: 100%;
	left: 0;
	list-style: none;
	overflow: hidden;
	position: relative;
	transition: left .8s cubic-bezier(.77, 0, .175, 1);
	width: 10000%;
}

.axs-slider-content > ul > li {
	float: left;
	height: 100%;
	position: relative;
	width: 1%;
}

.axs-slider-no-transition {
	transition: none !important;
}

input[type=radio]#axs-slider-control-1:checked ~ .axs-slider-content > ul {
	left: 0;
}

input[type=radio]#axs-slider-control-2:checked ~ .axs-slider-content > ul {
	left: -100%;
}

input[type=radio]#axs-slider-control-3:checked ~ .axs-slider-content > ul {
	left: -200%;
}

input[type=radio]#axs-slider-control-4:checked ~ .axs-slider-content > ul {
	left: -300%;
}

input[type=radio]#axs-slider-control-5:checked ~ .axs-slider-content > ul {
	left: -400%;
}

@media all and (min-width: 768px) {
	.axs-at-slider-arrow-left,
	.axs-at-slider-arrow-right {
		display: block;
	}
}
