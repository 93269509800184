:root {
	--grid-inner-padding: 4vw;
	--grid-inner-padding-half: calc(.5 * var(--grid-inner-padding));
	--column-1: calc(100% / 4);
	--column-2: calc(2 * var(--column-1));
	--column-3: calc(3 * var(--column-1));
	--column-4: calc(4 * var(--column-1));
	--column-5: calc(5 * var(--column-1));
	--column-6: calc(6 * var(--column-1));
	--column-7: calc(7 * var(--column-1));
	--column-8: calc(8 * var(--column-1));
	--column-9: calc(9 * var(--column-1));
	--column-10: calc(10 * var(--column-1));
	--column-11: calc(11 * var(--column-1));
	--column-12: calc(12 * var(--column-1));
}

@media all and (min-width: 768px) and (max-width: 1023px) {
	:root {
		--column-1: calc(100% / 6);
	}
}

@media all and (min-width: 1024px) {
	:root {
		--column-1: calc(100% / 12);
	}
}

@media all and (min-width: 1920px) {
	:root {
		--grid-inner-padding: calc(4 / 100 * 1920px);
	}
}
