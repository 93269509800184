/* wrapper for inner content sections with max-width, centered */
.axs-inner-content {
	margin-left: auto;
	margin-right: auto;
	max-width: 1280px;
	padding-left: 4%;
	padding-right: 4%;
	position: relative;
}

@media only all and (min-width: 1279px) {
	.axs-inner-content {
		padding-left: 2.5%;
		padding-right: 2.5%;
	}
}

/* =================================================================== */

.axs-row {
	margin-left: -.833rem;
	margin-right: -.833rem;
}

.columns {
	padding: 0 .833rem;
}

/* for grid purpose */
.axs-column-1 {
	float: left;
	width: 100%;
}

.axs-column-2 {
	float: left;
	width: 48%;
}

.axs-column-3 {
	float: left;
	width: 33.3333333333%;
}

.axs-column-3::after {
	clear: both;
	content: " ";
}

.axs-column-4 {
	float: left;
	width: 25%;
}

.axs-column-1-small {
	float: left;
	width: 100%;
}

.axs-column-6-small {
	float: left;
	width: 50%;
}

/* taken from _grid.scss */
.axs-row::before,
.axs-row::after {
	content: " ";
	display: table;
}

.axs-row::after {
	clear: both;
}

/* #{$mobile} */

@media all and (min-width: 0) {
	.axs-column-3 {
		width: 100%;
	}
}

/* {#{$tablet} */

@media all and (min-width: 769px) {
	.axs-row {
		margin-left: -20px;
		margin-right: -20px;
	}

	.axs-column-3-medium {
		float: left;
		width: 33.3333333333%;
	}

	.axs-column-2-medium {
		float: left;
		width: 50%;
	}

	.columns {
		padding: 0 20px;
	}
}

/* {$desktopHD} */

@media all and (min-width: 1281px) {
	.axs-row {
		margin-left: -25px;
		margin-right: -25px;
	}

	.axs-column-4-large {
		float: left;
		width: 25%;
	}

	.columns {
		padding: 0 25px;
	}
}
