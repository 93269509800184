.axs-layer-content {
	margin: 55px auto 0;
	/* 1080/1180 = 0,915254 || maximale Breite - padding 50/50px = 100px */
	max-width: 1080px;
	padding-bottom: 80px;
	position: relative;
	width: 91.53%;
	z-index: 1;
}

.axs-layer-opened {
	overflow: hidden;
}

.axs-layer-shader {
	background: rgba(0, 0, 0, .9);
	display: none;
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	transition: opacity .25s;
	width: 100%;
	z-index: 100;
}

.axs-layer-wrapper {
	height: 100%;
	left: 0;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	overflow-y: scroll;
	/* contains the scrollbar that is hidden on body by default in all layers */
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 101;
}

.axs-layer {
	margin: 0 auto;
	opacity: 0;
	position: relative;
	top: 0;
	transition: opacity .5s;
	width: 100%;
	z-index: 101;
}

.axs-layer-shader.axs-layer-shader-visible,
.axs-layer-fallback .axs-layer,
.axs-layer-visible.axs-layer {
	display: block;
	opacity: 1;
}

.axs-layer-inner {
	background: var(--color-white);
	float: left;
	height: 100%;
	position: relative;
	width: 100%;
}

@media all and (max-width: 768px) {
	.axs-layer-wrapper {
		min-height: 100vh;
		overflow-y: auto;
		position: absolute;
	}

	.axs-layer-opened > .axs-module,
	.axs-cms-layer-opened > .axs-module {
		display: none !important;
		overflow: hidden;
	}
}

@media all and (min-width: 1025px) {
	.axs-layer {
		/* 1080/1180 = 0,915254 || maximale Breite - padding 50/50px = 100px */
		/* min-width: 970px; */
		max-width: 970px;
		width: 86.53%;
	}
}
