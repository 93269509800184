/* Typography */

/*  fonts ==================================================================== */

@font-face {
	font-family: AudiTypeNormal;
	font-style: normal;
	font-weight: normal;
	src: url("../assets/fonts/AudiType-Normal_08.woff") format("woff");
}

@font-face {
	font-family: AudiTypeBold;
	font-style: normal;
	font-weight: normal;
	src: url("../assets/fonts/AudiType-Bold_08.woff") format("woff");
}

@font-face {
	font-family: AudiTypeExtendedNormal;
	font-style: normal;
	font-weight: normal;
	src: url("../assets/fonts/AudiType-ExtendedNormal_08.woff") format("woff");
}

@font-face {
	font-family: AudiTypeExtendedBold;
	font-style: normal;
	font-weight: normal;
	src: url("../assets/fonts/AudiType-ExtendedBold_08.woff") format("woff");
}

/* ============================================================================= */

/*  basic typography ============================================================ */

html {
	font-size: 62.5%; /* px units = rem units for easier maintenance */
	text-size-adjust: 100%;
}

/* headlines */

/*  axs = namespace
	at  = atom
	el  = element
	md  = module
*/

/* base class */

.axs-el-hdl {
	font-family: AudiTypeExtendedNormal, Verdana, sans-serif;
	font-weight: 300;
	margin-bottom: 20px;
}

.axs-el-hdl a {
	color: var(--color-black);
}

.axs-el-hdl a:hover {
	color: #c03;
}

/* atom: on dark background */
.axs-at-hdl-inv,
.axs-at-hdl-inv a {
	color: var(--color-white);
}

.axs-at-hdl-inv a:hover {
	/* color: #d0d3d4; */
	color: rgba(255, 255, 255, .7);
}

/* atom: font weight */
.axs-at-hdl-b {
	font-family: AudiTypeExtendedBold, Verdana, sans-serif;
}

/* atom: with fixed underline */
.axs-at-hdl-u::after {
	background: var(--color-black);
	content: "";
	display: block;
	height: 2px;
	margin-bottom: 70px;
	position: relative;
	top: 5px;
	width: 60px;
}

.axs-at-hdl-u.axs-at-hdl-inv::after {
	background: var(--color-white);
}

/*
.axs-el-hdl-01 = HLW
.axs-el-hdl-02 = HLM
.axs-el-hdl-03 = HL, HL-R
.axs-el-hdl-04 = HLT, HLT-R
.axs-el-hdl-05 = HLS, HLS-R
*/

/* HLW */
.axs-el-hdl-01 {
	font-size: 3.6rem;
	line-height: 4rem;
	margin: 30px 0 0;
}

.axs-el-hdl-01-large {
	font-size: 5rem;
	line-height: 6rem;
	margin: 30px 0 0;
}

/* HLM */
.axs-el-hdl-02 {
	font-size: 2.6rem;
	line-height: 3.6rem;
	margin: 0 0 14px;
}

/* HL, HL-R */
.axs-el-hdl-03 {
	font-size: 1.8rem;
	line-height: 3rem;
	margin-bottom: 20px; /* defined 30px - font specific spacing */
}

/* HLT, HLT-R */
.axs-el-hdl-04 {
	font-size: 1.5rem;
	line-height: 2rem;
}

/* HLS, HLS-R */
.axs-el-hdl-05 {
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.axs-el-hdl-02 + .axs-el-hdl-03 {
	margin-top: -12px;
}

.axs-el-hdl-02 + .axs-el-hdl-04 {
	margin-top: -8px;
}

.axs-el-hdl-02 + .axs-el-hdl-05 {
	margin-top: -8px;
}

/* paragraphs */

/* base class */

.axs-el-pg {
	/* margin-bottom: 21px; margins for paragraphs are set when needed */
	font-family: AudiTypeNormal, Verdana, sans-serif;
}

.axs-at-pg-b {
	font-family: AudiTypeBold, Verdana, sans-serif;
}

.axs-el-pg a {
	color: var(--color-black);
	text-decoration: underline;
}

.axs-el-pg a:hover {
	color: #c03;
}

/* on dark background */
.axs-at-pg-inv,
.axs-at-pg-inv a {
	color: var(--color-white);
}

.axs-at-pg-inv a:hover {
	color: rgba(255, 255, 255, .7);
}

.axs-at-pg-indicator,
.axs-at-pg-indicator a {
	color: #c03;
}

.axs-at-pg-variant,
.axs-at-pg-variant a {
	color: #6d7579;
}

/*
.axs-el-pg-01 = TXT-L
.axs-el-pg-02 = TXT-T
.axs-el-pg-03 = TXT, TXT-B, TXT-N, TXT-G
.axs-el-pg-04 = TXT-LST, TXT-LST-B, TXT-LST-N, TXT-LST-G
.axs-el-pg-05 = FN-TXT
*/

/* TXT-T */
.axs-el-pg-02 {
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-size: 1.3rem;
	line-height: 2.2rem;
}

/* TXT-L */
.axs-el-pg-01 {
	font-size: 2rem;
	line-height: 3.6rem;
}

/* TXT, TXT-B, TXT-N, TXT-G */
/* TXT-LST */
/* TXT-LST-B */
/* TXT-LST-N */
/* TXT-LST-G */
.axs-el-pg-03 {
	font-size: 1.4rem;
	line-height: 2.4rem;
}

.axs-el-pg-04 {
	font-size: 1.4rem;
	line-height: 2rem;
}

/* TXT-FN */
.axs-el-pg-05 {
	font-size: .75em;
	line-height: 1.1rem;
}

/* labels - short texts, definitions */

/*  .axs-el-lbl-01 = L-INFO, L-INFO-B
	.axs-el-lbl-02 = L-TXT
	.axs-el-lbl-03 = L-TXT-B
	.axs-el-lbl-04 = L-TXT-W
	.axs-el-lbl-05 = L-FN
*/

/* base class */

.axs-el-lbl {
	color: var(--color-black);
	font-family: AudiTypeNormal, Verdana, sans-serif;
	margin-bottom: 20px;
}

.axs-el-lbl a {
	color: var(--color-black);
}

.axs-el-lbl a:hover {
	color: #c03;
}

.axs-at-lbl-b {
	font-family: AudiTypeBold, Verdana, sans-serif;
}

/* on dark background */
.axs-at-lbl-inv,
.axs-at-lbl-inv a {
	color: var(--color-white);
}

.axs-at-lbl-inv a:hover {
	color: rgba(255, 255, 255, .7);
}

.axs-el-lbl-02,
.axs-el-lbl-04 {
	font-family: AudiTypeExtendedNormal, Verdana, sans-serif;
}

/* L-TXT-B */
.axs-el-lbl-03 {
	font-family: AudiTypeExtendedBold, Verdana, sans-serif;
}

/* L-FN */
/* use with sup or sub - basic.generic.css */
.axs-at-lbl-variant {
	color: #6d7579;
	margin-bottom: 0;
}

.axs-at-lbl-inv.axs-at-lbl-variant {
	color: #b0b6b8;
}

.axs-el-lbl-01 {
	font-size: 1.4rem;
	line-height: 1.8rem;
}

.axs-el-lbl-02,
.axs-el-lbl-03 {
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* L-TXT-W   */
.axs-el-lbl-04 {
	font-size: 3.6rem;
	line-height: 4rem;
}

/* L-FN */
.axs-el-lbl-05 {
	color: #6d7579;
	font-size: 1.2rem;
	line-height: 1.6rem;
	margin-bottom: 0;
}

.axs-el-lbl-06 {
	font-size: 1.2rem;
	line-height: 1.8rem;
}

/* base class */

.axs-el-lk {
	color: var(--color-black);
	cursor: pointer;
	font-family: Verdana, sans-serif;
	position: relative;
}

/* for links in lists a higher margin is required */
li.axs-el-lk {
	margin: 0 0 30px;
}

.axs-at-lk-b {
	font-weight: var(--font-weight-bold);
}

.axs-el-lk:hover {
	color: var(--color-black);
}

/* basic style before */
.axs-el-lk::before {
	content: "";
	display: inline-block;
	vertical-align: middle;
}

/* asset icon basics */
.axs-el-lk.axs-el-lk-icn::before {
	background-color: var(--color-black);
	background-position: center center;
	background-repeat: no-repeat;
	height: 32px;
	left: 0;
	position: absolute;
	top: 0;
	width: 32px;
}

.axs-el-lk.axs-el-lk-icn.axs-at-lk-highlight::before,
.axs-el-lk.axs-el-lk-icn.axs-at-lk-variant::before {
	background-color: #e7e9ea;
}

/* icon bg is changing colors by hovering the links */
.axs-el-lk.axs-el-lk-icn:hover::before {
	background-color: rgba(0, 0, 0, .7);
}

.axs-el-lk.axs-el-lk-icn.axs-at-lk-variant:hover::before {
	background-color: #d0d3d4;
}

.axs-el-lk.axs-el-lk-icn.axs-at-lk-highlight:hover::before {
	background-color: #424a52;
}

.axs-el-lk.axs-el-lk-icn {
	display: table-cell;
	height: 32px;
	padding-left: 40px;
	vertical-align: middle;
}

.axs-is-old-ff .axs-el-lk.axs-el-lk-icn {
	display: inline-block;
	height: auto;
	min-height: 24px;
	padding-top: 8px;
}

/* on dark background */
.axs-at-lk-inv {
	color: var(--color-white);
}

.axs-at-lk-inv:hover {
	color: rgba(255, 255, 255, .7);
}

.axs-at-pg-variant.axs-at-pg-inv {
	color: var(--color-grey-30);
}

.axs-at-lk-higlight {
	color: #d0d3d4;
}

.axs-el-lk-01 {
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* N-LNK (A3 navigation link) incl. Breadcrum */
.axs-el-lk-08,
.axs-el-lk-09 {
	font-family: AudiTypeExtendedBold, Verdana, sans-serif;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* N-LNK-R (A3 navigation link regular) */
.axs-el-lk-04 {
	font-family: AudiTypeExtendedNormal, Verdana, sans-serif;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

/* HL-LNK (A4 headline link) */
.axs-el-lk-05 {
	font-family: AudiTypeExtendedBold, Verdana, sans-serif;
	font-size: 1.5rem;
	line-height: 2rem;
	margin: 20px 0 0;
}

.axs-el-lk-05::before {
	/* arrow: red */
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjVENkU1MkFGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjVENkU1MkJGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNUQ2RTUyOEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNUQ2RTUyOUZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkibFJIAAAC+SURBVHjabJFLCsJAEERnxiAobjxL9CIiuhLEYxizEH9H0IUKggsVD+IiHkcEkYxVUIEhseGRXjy6pjs2M/HQGLMFbZCCFWiCd+yz3KgicFV/AAv1S1AHn0J0+lIec4rkGaWn7URlkXUHE/CSnIAv5FoRHdYF8F07xVPaQM6dqdYNnNXPQRf4f+IAjNTzCg9sX4nmqfagBaaMpVR+Yw8cdcM0lMKt++AkKdEiNoxymsQ/09D91kzCNB+KPwEGAKT0L5x12GxQAAAAAElFTkSuQmCC");
	height: 11px;
	margin-right: 4px;
	width: 10px;
}

.axs-el-lk-05.axs-at-lk-inv::before {
	/* arrow: white */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzM2Mjg4QTlGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzM2Mjg4QUFGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNUQ2RTUzMEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMzYyODhBOEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pjf35sgAAACFSURBVHjaYvj//384EL/7DwE1QMwIxNxAzATEDDAMY4AUf0FSDBJjw6YQhEOA+BuaYhZsCkE4AsnkaqgYMzaFIByG5OY6IGYFuRubQhCe9B8BrPCZ+B7JrUzYFIJ8/xmqqBKXr4OA+CuarzEUhiCZVAUVY0RXCDLpNRD/QQoSFnQTAQIMAF74Z0Je26jhAAAAAElFTkSuQmCC");
}

.axs-el-lk-05.axs-at-lk-inv:hover::before {
	/* arrow: lightgrey */
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjVENkU1MjZGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjVENkU1MjdGQjhCMTFFM0JBRjNFNDk5QUE1NzEwOTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODM4MkUyM0ZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ODM4MkUyNEZCOEIxMUUzQkFGM0U0OTlBQTU3MTA5NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtYjhecAAADMSURBVHjabJA7CsJAEIY3cSux8RwKHsBbhKA2HsDCCxiDhUp6G1OIYOlFxFIxnsLWRxNcvw0TWR8DH7Mz8zPM/t7hlHWVUkuoQwxzqMKj1Ww8lYRPsSUP4AZTiOjZt1ZuGGMK2BzCHQyMpafL+Vsogx5cRRxJr2KzV6idOGbnDimVmyeQQP4jFPGCNJSyzc07/4/IbuxLaV3Yq++fIbJWraAGIzYl5Uw7ooC0Fg9jV1T4KKKQtBGR9XFGz/u4ie8HcIHcsUS7tlleAgwAX0aMjSsbf2oAAAAASUVORK5CYII=");
}

/* Basic-Link */
.axs-el-lk-basic {
	color: var(--color-grey-70);
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-size: 1.4rem;
	line-height: 2rem;
	margin: 20px 0 0;
}

.axs-el-lk-basic.axs-at-lk-inv {
	color: var(--color-white);
}

.axs-el-lk-basic:hover {
	color: var(--color-black);
}

.axs-el-lk-basic.axs-at-lk-inv:hover {
	color: var(--color-grey-20);
}

.axs-el-lk-basic::after {
	background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' fill='rgb(76,76,76)'><polyline id='forward-small' points='9.704 18 9 17.29 14.127 12.203 9.005 7.122 9.709 6.412 15.547 12.203 9.704 18'></polyline></g></g></svg>");
	background-position: left -2px;
	background-repeat: no-repeat;
	background-size: 24px;
	content: "";
	display: inline-block;
	height: 20px;
	vertical-align: top;
	width: 20px;
}

.axs-el-lk-basic.axs-at-lk-inv::after {
	background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' fill='rgb(255,255,255)'><polyline id='forward-small' points='9.704 18 9 17.29 14.127 12.203 9.005 7.122 9.709 6.412 15.547 12.203 9.704 18'></polyline></g></g></svg>");
}

.axs-el-lk-basic:hover::after {
	background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' fill='rgb(0,0,0)'><polyline id='forward-small' points='9.704 18 9 17.29 14.127 12.203 9.005 7.122 9.709 6.412 15.547 12.203 9.704 18'></polyline></g></g></svg>");
}

.axs-el-lk-basic.axs-at-lk-inv:hover::after {
	background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='Artboard' fill='rgb(204,204,204)'><polyline id='forward-small' points='9.704 18 9 17.29 14.127 12.203 9.005 7.122 9.709 6.412 15.547 12.203 9.704 18'></polyline></g></g></svg>");
}

.axs-el-lk-08 {
	margin-right: 10px;
}

.axs-el-lk-08::after,
.axs-el-lk-09::after {
	background-position: left center;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	height: 15px;
	margin-left: 10px;
	vertical-align: middle;
	width: 9px;
}

.axs-el-lk-08::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
	width: 7px;
}

.axs-el-lk-09::after {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAICAYAAAArzdW1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGxJREFUeNpiOMNgfAmI/wMBAwij80GYiYGBYTIQXz7LaPKfAQLQ+QxMxv/PzAbS82AS6HyQIhYQAZSYABTgBrGhChmR+UwMRAAmqOoCIBUOxLpQU1D4TECBVCAnCUkBCh9mUi6yABY+A0CAAQBl9UkzJOUOdAAAAABJRU5ErkJggg==");
}

.axs-el-lk-10 {
	font-size: .75em;
	line-height: 1.1rem;
	text-decoration: underline;
}

/* Navigation Link */
.axs-el-lk-nav-02,
.axs-el-lk-nav-01 {
	color: var(--color-grey-70);
	font-family: AudiTypeExtendedNormal, Verdana, sans-serif;
	font-size: 1.4rem;
	line-height: 1.5rem;
	padding-bottom: 4px;
}

.axs-el-lk-highlight {
	border-bottom: 2px solid var(--color-black);
	color: var(--color-black);
}

.axs-el-lk-nav-01:active,
.axs-el-lk-nav-02:active {
	border-bottom: 2px solid var(--color-black);
	color: var(--color-black);
}

.axs-el-lk-nav-02.axs-at-li-inv,
.axs-el-lk-nav-01.axs-at-lk-inv {
	color: var(--color-grey-20);
}

.axs-el-lk-nav-02.axs-at-lk-inv:hover,
.axs-el-lk-nav-01.axs-at-lk-inv:hover {
	color: var(--color-white);
}

.axs-el-lk-nav-02.axs-at-lk-inv:active,
.axs-el-lk-nav-01.axs-at-lk-inv:active {
	border-bottom: 2px solid var(--color-white);
	color: var(--color-white);
}

.axs-el-lk-nav-01.inactive {
	color: var(--color-grey-50);
}

.axs-el-lk-nav-02 {
	font-family: AudiTypeNormal, Verdana, sans-serif;
}

.axs-el-lk-nav-02:hover {
	color: var(--color-black);
}

.axs-el-lk-nav-02.inactive {
	color: var(--color-grey-30);
}

/* single arrow up */
input[type="checkbox"]:checked + .axs-at-lk-dd::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTdCMjUxRjg3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTdCMjUxRjk3NzYwMTFFMkFDMThERTA5NEFENUM1QUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1N0IyNTFGNjc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1N0IyNTFGNzc3NjAxMUUyQUMxOERFMDk0QUQ1QzVBRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgslqbkAAABNSURBVHjaYvr//z8DDJ9hMP4PwshiTAxQcJbR5D+ngTIDCIPYMHEWZEmhcHew4DuGnWAxoHmMLMiSEhVJjFCN/2GKWLBIwthgRQABBgB5LTGo9okstAAAAABJRU5ErkJggg==");
}

/* AXS - New SVG - Link */

.axs-el-lk > svg {
	display: inline-block;
	height: 14px;
	vertical-align: middle;
	width: 26px;
}

.axs-el-lk span.axs-link-icon {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: 14px;
	vertical-align: middle;
	width: 26px;
}

.axs-el-lk span.axs-link-text {
	margin-left: 10px;
}

/*  margins between elements and modules */
.axs-el-pg + .axs-el-hdl {
	margin-top: 16px;
}

.axs-el-pg + .axs-module {
	margin-top: 23px;
}

/*  media queries */
/* Breakpoint 749px */

@media all and (max-width: 749px) {
	.axs-el-pg-01 {
		font-size: 1.8rem;
		line-height: 3rem;
	}
}

@media all and (max-width: 768px) {
	/* HLW */
	.axs-el-hdl-01,
	.axs-el-hdl-01-large {
		font-size: 2.6rem;
		line-height: 3rem;
	}

	/* HLM */
	.axs-el-hdl-02 {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}

	/* HL */
	.axs-el-hdl-03 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	/* HLT */
	.axs-el-hdl-04 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	/* TXT-L */
	.axs-el-pg-01 {
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	/* L-TXT-W   */
	.axs-el-lbl-04 {
		font-size: 2.6rem;
		line-height: 3rem;
	}

	.axs-el-lk-04 {
		font-size: 1.2rem;
		line-height: 1.5rem;
	}

	.axs-at-hdl-u::after {
		margin-bottom: 40px;
	}
}
