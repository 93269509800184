:root {
	--axs-header-height: 60px;
}

.axs-body--header-open {
	overflow: hidden;
}

.axs-header-navigation__placeholder {
	box-sizing: content-box;
	height: var(--axs-header-height);
}

.axs-header-navigation {
	display: block;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
}

.axs-header-navigation__top-bar-wrapper {
	position: relative;
	transition: box-shadow var(--time-l) var(--transition-ease-out);
	z-index: -1;
}

.axs-header-navigation__top-bar-wrapper::after {
	background: var(--color-black);
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity var(--time-l) var(--transition-ease-out);
	z-index: -1;
}

.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__top-bar-wrapper::after {
	opacity: .9;
}

.axs-header-navigation__top-bar {
	background-color: var(--color-white);
	height: var(--axs-header-height);
	padding-bottom: var(--space-m);
	padding-left: var(--grid-inner-padding);
	padding-right: var(--grid-inner-padding);
	padding-top: var(--space-m);
}

.axs-header-navigation__logo-link {
	display: block;
}

.axs-header-navigation__logo {
	height: 12px;
	margin: 6px auto;
}

.axs-header-navigation__main-navigation {
	background-color: var(--color-white);
	max-height: 0;
	overflow: hidden;
	padding-left: var(--grid-inner-padding);
	padding-right: var(--grid-inner-padding);
	transition: max-height var(--transition-ease-out) var(--time-l);
}

.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__top-bar-wrapper {
	box-shadow: 0 0 5px 0 var(--color-grey-10);
}

.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation {
	max-height: calc(100vh - var(--axs-header-height));
	overflow-y: auto;
}

.axs-header-navigation__main-nav-toggle {
	cursor: pointer;
	display: block;
	font-size: 1.2rem;
	height: 24px;
	overflow: hidden;
	right: 0;
	text-indent: -100000px;
	top: 0;
	white-space: nowrap;
	width: 24px;
	z-index: 10;
}

.axs-header-navigation__main-nav-toggle-container {
	color: var(--color-black);
	display: block;
	height: 24px;
	position: absolute;
	width: 24px;
}

.axs-header-navigation__main-nav-toggle-container::before,
.axs-header-navigation__main-nav-toggle-container::after,
.axs-header-navigation__main-nav-toggle::before {
	background-color: var(--color-black);
	content: "";
	height: 1px;
	left: 3px;
	position: absolute;
	transform: translate3d(0, 0, 0) rotate(0);
	transform-origin: center;
	transition: all var(--time-l) var(--transition-ease-out);
	width: 18px;
}

.axs-header-navigation__main-nav-toggle-container::before {
	top: 8px;
}

.axs-header-navigation__main-nav-toggle::before {
	top: 12px;
}

.axs-header-navigation__main-nav-toggle-container::after {
	top: 16px;
}

.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__top-bar-wrapper .axs-header-navigation__main-nav-toggle-container::before {
	top: 1px;
	transform: translate3d(0, 11px, 0) rotate(-45deg);
}

.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__top-bar-wrapper .axs-header-navigation__main-nav-toggle-container .axs-header-navigation__main-nav-toggle::before {
	top: 1px;
	transform: translate3d(0, 11px, 0) rotate(45deg);
}

.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__top-bar-wrapper .axs-header-navigation__main-nav-toggle-container::after {
	opacity: 0;
}

.axs-header-navigation__link {
	margin-top: var(--space-xl);
}

.axs-header-navigation__link-text--special {
	font-weight: var(--font-weight-bold);
}

.axs-header-navigation__links-lang-wrapper {
	display: block;
	overflow: hidden;
}

.axs-header-navigation__links:last-child {
	margin-bottom: var(--space-xl);
}

/* language menu */
.axs-header-navigation__language-icon--small {
	height: var(--icon-size-small);
	left: calc(-1 * (var(--space-s) + var(--icon-size-small)));
	position: absolute;
	stroke: var(--color-black);
	top: 0;
	width: var(--icon-size-small);
}

.axs-header-navigation__language-menu {
	border-top: 1px solid var(--color-grey-15);
	margin-top: var(--space-xl);
	padding-top: var(--space-l);
}

.axs-header-navigation__language-menu.axs-header-navigation--no-links {
	border-top: none;
	margin-top: 0;
}

.axs-header-navigation__selected-language-wrapper {
	color: var(--color-black);
	display: block;
	height: var(--icon-size-small);
	margin-bottom: var(--space-xl);
	margin-left: calc(var(--icon-size-small) + var(--space-s));
	overflow: visible;
	position: relative;
}

.axs-header-navigation__selected-language {
	display: block;
	float: left;
	line-height: var(--icon-size-small);
	padding-right: var(--space-xxs);
}

.axs-header-navigation__arrow-icon--small {
	height: var(--icon-size-small);
	transition: all var(--time-l) var(--transition-ease-out);
	width: var(--icon-size-small);
}

.axs-header-navigation__lang-nav-toggle-input:checked ~ .axs-header-navigation__selected-language-wrapper .axs-header-navigation__arrow-icon--small {
	transform: scaleY(-1);
}

.axs-header-navigation__lang-nav-toggle-input:checked ~ .axs-header-navigation__selected-language-wrapper {
	margin-bottom: var(--space-l);
}

.axs-header-navigation--no-links .axs-header-navigation__arrow-icon--small {
	display: none;
}

.axs-header-navigation__language-links-wrapper {
	max-height: 0;
	overflow: hidden;
	transition: max-height var(--transition-ease-out) var(--time-l);
}

.axs-header-navigation__lang-nav-toggle-input:checked ~ .axs-header-navigation__language-links-wrapper,
.axs-header-navigation--no-links .axs-header-navigation__language-links-wrapper {
	max-height: 100vh;
}

.axs-header-navigation__language-link-wrapper {
	margin-bottom: var(--space-l);
}

.axs-header-navigation__language-links {
	margin-bottom: var(--space-xl);
	margin-left: calc(var(--icon-size-small) + var(--space-s));
}

.axs-header-navigation__language-link {
	color: var(--color-grey-60);
	height: var(--icon-size-small);
}

.axs-header-navigation__language-link:hover {
	color: var(--color-black);
}

@media all and (min-width: 768px) {
	:root {
		--axs-header-height: 72px;
	}

	.axs-header-navigation__top-bar {
		padding-bottom: var(--space-l);
		padding-top: var(--space-l);
	}

	.axs-body--header-open {
		overflow: auto;
	}

	.axs-header-navigation__top-bar-wrapper::after {
		display: none;
	}

	.axs-header-navigation__main-navigation {
		background-color: var(--color-grey-05);
		margin-left: var(--grid-inner-padding);
		margin-right: var(--grid-inner-padding);
		padding-left: var(--space-xl);
		padding-right: var(--space-xl);
		position: absolute;
		width: 350px;
	}

	.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation {
		overflow-y: auto;
	}

	.axs-header-navigation__logo {
		height: 20px;
		margin: 2px auto;
	}
}

@media all and (min-width: 1024px) {
	:root {
		--axs-header-navigation-offset: calc(24px + var(--space-l)); /* 24px = header inner height (72px - space-l - space-l) */
	}

	.axs-header-navigation__top-bar {
		padding-bottom: var(--space-l);
		padding-top: var(--space-l);
	}

	.axs-header-navigation__main-nav-toggle-container {
		right: var(--grid-inner-padding);
	}

	.axs-header-navigation__logo {
		margin-left: 0;
		margin-right: 0;
		padding-right: var(--space-xl);
	}

	.axs-header-navigation__main-navigation-wrapper {
		position: relative;
	}

	.axs-header-navigation__main-navigation {
		overflow: visible;
		padding-left: var(--space-l);
		padding-right: var(--space-l);
		position: absolute;
		right: 0;
		top: 0;
	}

	.axs-header-navigation__main-nav-toggle-input ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation > *:not(.axs-header-navigation__special-links-wrapper--in-topbar) {
		max-height: 0;
		transition: max-height var(--time-l);
	}

	.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation > *:not(.axs-header-navigation__special-links-wrapper--in-topbar) {
		max-height: calc(100vh - var(--axs-header-height));
		overflow-y: auto;
	}

	.axs-header-navigation__main-navigation > *:not(.axs-header-navigation__special-links-wrapper--in-topbar) {
		overflow: hidden;
	}

	.axs-header-navigation__special-links-wrapper.axs-header-navigation__special-links-wrapper--in-topbar {
		position: absolute;
		right: calc(var(--icon-size-small));
		top: calc(-1 * var(--axs-header-navigation-offset));
	}

	.axs-header-navigation__special-links-wrapper--in-topbar .axs-header-navigation__special-links {
		display: flex;
	}

	.axs-header-navigation__special-links-wrapper--in-topbar .axs-header-navigation__link--special {
		margin-right: var(--space-xl);
		margin-top: 0;
		white-space: nowrap;
	}

	.axs-header-navigation__link {
		margin-top: var(--space-m);
	}

	.axs-header-navigation__language-menu {
		margin-top: var(--space-m);
	}

	.axs-header-navigation__special-links-wrapper--in-topbar .axs-header-navigation__link--selected::after,
	.axs-header-navigation--no-flyout .axs-header-navigation__link--selected::after {
		background-color: var(--color-black);
		content: "";
		display: block;
		height: 2px;
		position: relative;
		width: 100%;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__menu-toggle {
		display: none;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__main-navigation {
		background: none;
		display: flex;
		padding-right: 0;
		top: calc(-1 * var(--axs-header-navigation-offset));
		width: auto;
	}

	.axs-header-navigation__main-nav-toggle-input:checked ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation {
		max-height: calc(100vh - var(--axs-header-height));
		overflow: visible;
		padding-bottom: 0;
	}

	.axs-header-navigation__selected-language-wrapper {
		margin-bottom: var(--space-m);
	}

	.axs-header-navigation__lang-nav-toggle-input:checked ~ .axs-header-navigation__selected-language-wrapper {
		margin-bottom: var(--space-m);
	}

	.axs-header-navigation__language-links {
		margin-bottom: var(--space-m);
	}

	.axs-header-navigation__language-link-wrapper {
		margin-bottom: var(--space-s);
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__special-links,
	.axs-header-navigation--no-flyout .axs-header-navigation__links,
	.axs-header-navigation--no-flyout .axs-header-navigation__links-lang-wrapper {
		display: flex;
		flex-wrap: nowrap;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__link {
		margin-right: var(--space-xl);
		margin-top: 0;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__links:last-child .axs-header-navigation__link {
		margin-right: 0;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__language-menu {
		border-left: 1px solid var(--color-grey-40);
		border-top: 0;
		height: 24px;
		margin-left: calc(var(--space-m) - var(--space-xl));
		margin-top: 0;
		padding-left: var(--space-m);
		padding-top: 0;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__language-menu.axs-header-navigation--no-links {
		border-left: none;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__language-links-wrapper {
		background-color: var(--color-grey-05);
		position: absolute;
		right: 0;
		top: var(--axs-header-navigation-offset);
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__language-link {
		width: calc(147px - 2 * var(--space-m));
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__language-links {
		margin-left: 0;
		padding: var(--space-s) var(--space-m) 0;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__main-nav-toggle-input ~ .axs-header-navigation__main-navigation-wrapper .axs-header-navigation__main-navigation > *:not(.axs-header-navigation__special-links-wrapper--in-topbar) {
		overflow: visible;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__lang-nav-toggle-input:checked ~ .axs-header-navigation__language-links-wrapper {
		max-height: 100vh; /* kind of random, must be well above max possible height */
	}

	.axs-header-navigation--no-links .axs-header-navigation__arrow-icon--small {
		display: block;
	}

	.axs-header-navigation--no-links .axs-header-navigation__language-links-wrapper {
		max-height: 0;
	}

	.axs-header-navigation__special-links-wrapper--in-topbar + .axs-header-navigation__links-lang-wrapper {
		display: block;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__special-links-wrapper--in-topbar + .axs-header-navigation__links-lang-wrapper {
		display: flex;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__links-lang-wrapper {
		overflow: visible;
	}

	.axs-header-navigation--no-flyout .axs-header-navigation__language-link-wrapper:last-child {
		margin-bottom: 0;
	}
}
