.axs-scroll-to-top {
	bottom: 20px;
	display: none;
	margin: 0;
	position: fixed;
	right: 0;
	z-index: 100;
}

.axs-scroll-to-top[is-active="true"] {
	display: block;
}

.axs-scroll-to-top_arrow-up {
	background-color: var(--color-black);
	fill: var(--color-white);
	height: 4.8rem;
	width: 4.8rem;
}
