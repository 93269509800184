.axs-icon-close:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAASUExURf///0NMU4qPlP///1tiafP09IWaeY4AAAABdFJOUyaS612bAAAAS0lEQVQoz2NgoB9gFEQBAkNP0AhEKKMJKisKCgoZoQmCBEASqGYqK0IUoggKmTgrYtruGiJInCBW7dgsEgrC4iSsjsfqzaEacfQBAEUgIbsNcOY8AAAAAElFTkSuQmCC");
}

.axs-icon-close::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAASUExURf///wAAAGBgYP///yAgIO/v7x19fBwAAAABdFJOUyaS612bAAAAS0lEQVQoz2NgoB9gFEQBAkNP0AhEKKMJKisKCgoZoQmCBEASqGYqK0IUoggKmTgrYtruGiJInCBW7dgsEgrC4iSsjsfqzaEacfQBAEUgIbsNcOY8AAAAAElFTkSuQmCC");
}

.axs-at-lk-variant.axs-icon-routing::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACZUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIHSjKcAAAAydFJOUwC/IGDf5UDvEICfOZqPM1wuB88dUKyronN7G2igNVOQXc20RCmvcHIwfFY6lhyNBMUf9BURZQAAAPBJREFUOMvVUUdyxDAMowpFad239562JUX/f1xoXzLemD5mJjyIB0AgQQD8dS03m2UfHiyiDTK+wG1VbXEh4SYm4Bwk0QiEdeAZPCGsBQIZOMR4AEMCYTqHHeIO5lOBcJmB3+89zAqBMMKkblccSTbeJvU7eRHvkKMDSDCXL7VKAdJVz6lLLHMs+8I4pelJRv3x64x4vh+9ECW9a1CfoG8UOijGqgED1YfmpuxjXs4qdshYUTTfnbKundNQ100rilkj74ftxHzGH32IwTPHgA+UPa7B8jRoZFiennSHCwbGP/t01pih198OWmaJnuH/1TdNGgtOra4ulgAAAABJRU5ErkJggg==");
}

.axs-at-lk-variant.axs-icon-url::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOV/WOUAAAAQdFJOUwC/QICPIO+fYBDfUK/PMHACrhk6AAAAlUlEQVQoz+2SMRIDIQhFQREQdf33P23cncymWotUKcK87qHzYSD613dVMh0nnugil3rx1geKoFgXJmWCqze46/08PODhCUmRUM0MZv3WMiCMQjp6jE5NQyL81g0jYRAZ5oIqcPIJx5N4dTc+Gq9EYiTyPMl+UJ17jV43mgFtz9oA2LPOAG8+b3ntb5tOYheOqtVfv7MXXUgFF1xPwtkAAAAASUVORK5CYII=");
}

.axs-at-lk-variant.axs-icon-enquiry::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJuxkb8AAAAPdFJOUwC/QIDvIHDPn2AQ3zCPUNDR54IAAACBSURBVBjTY2AgGRxSUlLSQuJLCwoKuiPxhYFYf0D58v8hwBPKl1CCgPwACF8YqpJfAcpnBXMD4Hz9CUCa8yeEL2i8me0HkO5PgMszqJQyLHdC6GdgLdFxD0DiM3D872JA5jNMg9uXIuiMYv8y470ofAaG94IQIA/lMxlDQQDJkQUAZcUf4ES0jQkAAAAASUVORK5CYII=");
}

.axs-at-lk-variant.axs-icon-vcard::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYBAMAAAAIZcBFAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJuxkb8AAAAPdFJOUwC/QIDPr1AQj+9gMN+fIFsAo1gAAAB/SURBVBjTY9ikBAUKDCKCYCAycQaDEAMYMAkkIpiKuJmJggtgTDE1AQYmJ0MQUwAImf7/gDMFFoKYISIBMLVFSgUQJnsg0N44MJPpEtAtwRCmAdAGY7A2JoMDDNHGYMOYDD4wCBmDrYAywWqFPxufFYYYBvKNHi73JiJ7E+F5AL5/H4JGCx0nAAAAAElFTkSuQmCC");
}

.axs-at-lk-highlight.axs-icon-vcard::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYBAMAAAAIZcBFAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJuxkb8AAAAPdFJOUwC/QIDPr1AQj+9gMN+fIFsAo1gAAAB/SURBVBjTY9ikBAUKDCKCYCAycQaDEAMYMAkkIpiKuJmJggtgTDE1AQYmJ0MQUwAImf7/gDMFFoKYISIBMLVFSgUQJnsg0N44MJPpEtAtwRCmAdAGY7A2JoMDDNHGYMOYDD4wCBmDrYAywWqFPxufFYYYBvKNHi73JiJ7E+F5AL5/H4JGCx0nAAAAAElFTkSuQmCC");
}

.axs-at-lk-highlight.axs-icon-url:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUxpcf////////////////////////////////////////////////////////////////Hv/K4AAAAQdFJOUwC/QICPIO+fYBDfUK/PMHACrhk6AAAAlUlEQVQoz+2SMRIDIQhFQREQdf33P23cncymWotUKcK87qHzYSD613dVMh0nnugil3rx1geKoFgXJmWCqze46/08PODhCUmRUM0MZv3WMiCMQjp6jE5NQyL81g0jYRAZ5oIqcPIJx5N4dTc+Gq9EYiTyPMl+UJ17jV43mgFtz9oA2LPOAG8+b3ntb5tOYheOqtVfv7MXXUgFF1xPwtkAAAAASUVORK5CYII=");
}

.axs-at-lk-highlight.axs-icon-url::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOV/WOUAAAAQdFJOUwC/QICPIO+fYBDfUK/PMHACrhk6AAAAlUlEQVQoz+2SMRIDIQhFQREQdf33P23cncymWotUKcK87qHzYSD613dVMh0nnugil3rx1geKoFgXJmWCqze46/08PODhCUmRUM0MZv3WMiCMQjp6jE5NQyL81g0jYRAZ5oIqcPIJx5N4dTc+Gq9EYiTyPMl+UJ17jV43mgFtz9oA2LPOAG8+b3ntb5tOYheOqtVfv7MXXUgFF1xPwtkAAAAASUVORK5CYII=");
}

.axs-at-lk-highlight.axs-icon-testdrive:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUxpcf////////////////////////////////////////////////////////////////Hv/K4AAAAQdFJOUwBwz1Awr0CfYO+AEN+/jyAUiM2XAAAAeUlEQVQoz83RyxKAIAgFUB+goFb8/9eWObUSaikbZzwzCFfnFq8tmxzFdvp0+uEVLCfWHyFBKcSq594fNI9jOs15H6fi7bmde3pXBykT9wJmSJmHp1oYVPeCDttsvNC9c8AZ19vhanMo0XHq+SU9Wo8cja/lAG79OgF9qgOsYY0XWwAAAABJRU5ErkJggg==");
}

.axs-at-lk-highlight.axs-icon-testdrive::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOV/WOUAAAAQdFJOUwBwz1Awr0CfYO+AEN+/jyAUiM2XAAAAeUlEQVQoz83RyxKAIAgFUB+goFb8/9eWObUSaikbZzwzCFfnFq8tmxzFdvp0+uEVLCfWHyFBKcSq594fNI9jOs15H6fi7bmde3pXBykT9wJmSJmHp1oYVPeCDttsvNC9c8AZ19vhanMo0XHq+SU9Wo8cja/lAG79OgF9qgOsYY0XWwAAAABJRU5ErkJggg==");
}

.axs-at-lk-highlight.axs-icon-service::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAhUExURUxpcQoKDwgKDQcKDQkKDQkLDQcLDw8PDwkLDggLDQkLDv++9VoAAAAKdFJOUwAw72C/cEAQn8/kqVBEAAAAcUlEQVQY02NgoBFg1FqKwheymI7Cd+VKKEXmuzGrzULWrshg1YWs3YCBwQhJWpmBMVURRdrIRQBZmlkRVbeRAAMuaREnFGlG1UQUaSaQ1Ui6uboMGJEN55qmzOaAxGctyGJF5jOwz0DWDlSwSpGBXgAA+dgOa1Yaad4AAAAASUVORK5CYII=");
}

.axs-at-lk-highlight.axs-icon-routing:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACZUExURUxpcf///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////3fS4mYAAAAydFJOUwC/IGDf5UDvEICfOZqPM1wuB88dUKyronN7G2igNVOQXc20RCmvcHIwfFY6lhwExY0fzInRrgAAAPBJREFUOMvVUUdyxDAMowpFad239562JUX/f1xoXzLemD5mJjyIB0AgQQD8dS03m2UfHiyiDTK+wG1VbXEh4SYm4Bwk0QiEdeAZPCGsBQIZOMR4AEMCYTqHHeIO5lOBcJmB3+89zAqBMMKkblccSTbeJvU7eRHvkKMDSDCXL7VKAdJVz6lLLHMs+8I4pelJRv3x64x4vh+9ECV9alAfoG8UOijGqgED1bvmpuxjXs4qdshYUTTfnbKundNQ100rilkj74ftxHzGH32IwTPHgA+UPa7B8jRoZFiennSHCwbGP/t01pih198OWmaJnuH/1TdNQwtObnZPnwAAAABJRU5ErkJggg==");
}

.axs-at-lk-highlight.axs-icon-routing::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACZUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIHSjKcAAAAydFJOUwC/IGDf5UDvEICfOZqPM1wuB88dUKyronN7G2igNVOQXc20RCmvcHIwfFY6lhyNBMUf9BURZQAAAPBJREFUOMvVUUdyxDAMowpFad239562JUX/f1xoXzLemD5mJjyIB0AgQQD8dS03m2UfHiyiDTK+wG1VbXEh4SYm4Bwk0QiEdeAZPCGsBQIZOMR4AEMCYTqHHeIO5lOBcJmB3+89zAqBMMKkblccSTbeJvU7eRHvkKMDSDCXL7VKAdJVz6lLLHMs+8I4pelJRv3x64x4vh+9ECW9a1CfoG8UOijGqgED1YfmpuxjXs4qdshYUTTfnbKundNQ100rilkj74ftxHzGH32IwTPHgA+UPa7B8jRoZFiennSHCwbGP/t01pih198OWmaJnuH/1TdNGgtOra4ulgAAAABJRU5ErkJggg==");
}

.axs-at-lk-highlight.axs-icon-print::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeAQMAAAAB/jzhAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAGUExURUxpcQAAAJpcoR0AAAABdFJOUwBA5thmAAAANElEQVQI12NgIBbYNwAJhQMgYgGI+AAiBGCE/gUY6/0dBgZGBSEYYf8JSDAwwYj//4ixCwDqkgpmBTfUFwAAAABJRU5ErkJggg==");
}

.axs-at-lk-highlight.axs-icon-enquiry:hover::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcf////////////////////////////////////////////////////////////rDiYQAAAAPdFJOUwC/QIDvIHDPn2AQ3zCPUNDR54IAAACBSURBVBjTY2AgGRxSUlLSQuJLCwoKuiPxhYFYf0D58v8hwBPKl1CCgPwACF8YqpJfAcpnBXMD4Hz9CUCa8yeEL2i8me0HkO5PgMszqJQyLHdC6GdgLdFxD0DiM3D872JA5jNMg9uXIuiMYv8y470ofAaG94IQIA/lMxlDQQDJkQUAZcUf4ES0jQkAAAAASUVORK5CYII=");
}

.axs-at-lk-highlight.axs-icon-enquiry::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJuxkb8AAAAPdFJOUwC/QIDvIHDPn2AQ3zCPUNDR54IAAACBSURBVBjTY2AgGRxSUlLSQuJLCwoKuiPxhYFYf0D58v8hwBPKl1CCgPwACF8YqpJfAcpnBXMD4Hz9CUCa8yeEL2i8me0HkO5PgMszqJQyLHdC6GdgLdFxD0DiM3D872JA5jNMg9uXIuiMYv8y470ofAaG94IQIA/lMxlDQQDJkQUAZcUf4ES0jQkAAAAASUVORK5CYII=");
}

.axs-icon-arrow-up-large,
.axs-icon-back-large,
.axs-icon-cancel-small,
.axs-icon-forward-large,
.axs-icon-system-play-large {
	display: inline-block;
	fill: currentColor;
	height: 4.8rem;
	stroke: currentColor;
	stroke-width: 0;
	width: 4.8rem;
}

.axs-icon-system-play-large.white {
	fill: var(--color-white);
}

.axs-icon-cancel-small {
	height: 2.4rem;
	width: 2.4rem;
}
