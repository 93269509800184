.axs-cookie-notification {
	background: var(--color-darkgrey);
	display: block;
	height: auto;
	margin: 0;
	overflow: hidden;
	padding: 0;
	top: 0;
	width: 100%;
	z-index: 100;
}

.axs-cookie-notification__inner-wrapper {
	padding: 13px var(--grid-inner-padding);
}

.axs-cookie-notification__text {
	color: var(--color-white);
}

.axs-cookie-notification__links {
	display: flex;
	justify-content: flex-end;
	padding-top: 15px;
}

.axs-cookie-notification__button-continue {
	padding-left: 20px;
}

.axs-cookie-notification__link-arrow {
	fill: var(--color-white);
	height: 20px;
}
