/*	=========================================================================
	Typography
	========================================================================= */

/*	basic typography
============================================================================= */

h1 {
	font: normal 36px/40px AudiTypeExtendedBold, Verdana, sans-serif;
}

h2,
.axs-h2 {
	clear: both;
	font: normal 18px/30px AudiTypeExtendedBold, Verdana, sans-serif;
}

/* Headline popup */

h3,
h4 {
	font: normal 14px/20px AudiTypeExtendedBold, Verdana, sans-serif;
}

h5 {
	font: normal 12px/20px AudiTypeExtendedBold, Verdana, sans-serif;
}

h6 {
	font: normal 12px/20px Verdana, sans-serif;
}

/*	bodycopy (line-height: 1 is the undefined default)
============================================================================= */

/* body copy */
body {
	font: normal 11px/20px Verdana, sans-serif;
}

.axs-c3 {
	font: bold 11px/1 Verdana, sans-serif;
}

/* tabbuttons and buttons */

.axs-b1 {
	font: bold 10px/1 Verdana, sans-serif;
}
